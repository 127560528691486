(function(window) {
   window.techsmith = window.techsmith || {};
   window.techsmith.strings = window.techsmith.strings || {};
   window.techsmith.strings.common = {
      success: "Success!",
      fail: "Fail!",
      yes: "Yes",
      no: "No",
      ok: "OK",
      cancel: "Cancel",
      delete: "Delete",
      wishToContinue: "Do you wish to continue?",

      fillInTheBlank: "Fill in the Blank",
      shortAnswer: "Free Response",
      multipleChoice: "Multiple Choice",
      trueFalse: "True or False",
   };
}(window));