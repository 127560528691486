(function(window) {
   window.techsmith = window.techsmith || {};
   window.techsmith.strings = window.techsmith.strings || {};
   window.techsmith.strings.assessment = {
      zeroTimeQuestionHeader: "Question sets can't be moved once you click Add New Question.",
      zeroTimeQuestionMessage: "Click or tap a point on the video timeline to add questions to that spot.",
      errorCantPublishEmptyQuestions: "We're sorry! You cannot publish a question with empty text.  Please enter text for all questions and try again.",
      errorCantPublishInvalidFields: "We're sorry! You have invalid fields in your assessment.  Please correct them and try again.",
      errorCantPublishNoAnswerForMultipleChoice: "We're sorry! You cannot publish a multiple choice question without answer choices. Please add choices or delete the question before saving your quiz.",
      errorInvalidEmail: "Invalid Email",
      publishErasesOldResponses: "Saving these changes will erase any existing quiz results for this video.",
      discardChanges: "Are you sure you want to discard your unsaved changes?",
      quizWillRevertToLastSavedState: "This quiz will revert to its last saved state.",
      discard: "Discard",
      discarding: "Discarding...",
      continueEditing: "Continue Editing",
      publish: "Publish",
      continue: "Continue",
      saving: "Saving...",
      republish: "Republish",
      savedAt: "Autosaved draft at ",
      autoSaving: "Autosaving draft...",
      saveFailed: "Save failed!",
      defaultQuizTitle: "Quiz",

      shareLinkAssessment: "Link to this Assessment",
      shareEmbedVideo: "Code to embed this video",
      shareReadyToCopy: "ready to copy",
      saveButtonText: "Save Quiz",
      discardButtonText: "Discard Changes",
      returnToVideoButtonText: "Back to Video",
      returnToImageButtonText: "Back to Image",
      quizTitlePlaceholder: "Title",
      quizEmailLabel: "Gather viewer's name and email address",
      shouldSendEmail: "Send nightly email reports to:",
      answerPlaceholder: "Answer",
      answerDefaultText: "Default Answer Text",
      deleteAnswer: "Delete Answer",
      addChoice: "Add Choice",
      addAnswer: "Add Answer",
      deleteQuestion: "Delete Question",
      questionPlaceholder: "Type a question",
      questionTypes: "Question Types",
      freeResponseMessage: "(This question will not be graded)",
      questionLabel: "Question:",
      answerLabel: "Answer:",
      addFeedback: "+ Add Feedback",
      feedbackTitle: "Feedback",
      feedbackIfCorrect: "If correct:",
      feedbackCorrectLabel: "Correct feedback text",
      feedbackCorrectGoToUrlLabel: "Correct feedback go-to url",
      feedbackIncorrectLabel: "Incorrect feedback text",
      feedbackIncorrectGoToUrlLabel: "Incorrect feedback go-to url",
      feedbackIfIncorrect: "If incorrect:",
      feedbackAction: "Action:",
      feedbackActionContinue: "Continue",
      feedbackActionJumpToTime: "Jump to Time",
      feedbackActionGoToUrl: "Go to URL",
      feedbackTimeFormatString: "Time (MMM:SS.000)",
      feedbackUnscoredPlaceholder: "Feedback is only available for graded questions",

      nextQuestionSet: "Next group of questions",
      previousQuestionSet: "Previous group of questions",

      errorVideoPlaybackCancelled: "Video playback has been cancelled.",
      errorVideoPlaybackNetwork: "There is a problem  with the internet connection - please try reloading the page.",
      errorVideoPlaybackDecode: "The video will not play - you may still add questions.",
      errorVideoPlaybackBrowser: "Your browser will not play this video.",
      errorVideoPlaybackUnknown: "An unknown error occurred. If you continue to experience issues, please contact our <a href='http://techsmith.custhelp.com/app/utils/login_form/redirect/ask'>Technical Support team.</a>",
      errorVideoPlaybackYouTubeInvalidParameterValue: "We're sorry, but this YouTube URL format is not supported when using the quizzing feature.<p/>Please see our <a href=\"https://support.techsmith.com/hc/en-us/articles/360048475091\">support article</a> for additional options.",
      errorVideoPlaybackYouTubeNoPlayInHtml5: "The video cannot be played.",
      errorVideoPlaybackYouTubeNotFound: "This video has been removed from YouTube and is no longer available for playback.",
      errorVideoPlaybackYouTubeNoEmbeddedPlayers: "This video is unavailable. Its YouTube owner either disabled embedding or marked it private.",
      errorLibraryNotFound: "Required library not found.",
      errorSeekPointNoTime: "time required for adding seek points",
      errorSeekPointZeroDuration: "cannot add seek point to zero-duration media",
      errorSeekPointExists: "seek point already exists at time ",
      errorSeekPointDoesNotExist: "seek point does not exist at time ",

      errorCorrectIndexNumber: "Correct Answer Index must be a number.",
      errorInvalidCorrectIndex: "Invalid correct answer index",
      errorAnswerListInvalidAnswers: "Answer list has invalid answers.",
   };
}(window));