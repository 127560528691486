(function(window) {
   window.techsmith = window.techsmith || {};
   window.techsmith.strings = window.techsmith.strings || {};
   window.techsmith.strings.report = {
      noReportsAvailable: "No reports are available. No users have responded.",
      hasNotBeenDeployed: "No reports are available. Draft has not been published.",
      questionTimeGraphQuestionNumber: 'Question<br/> Number',
      questionTimeGraphContentWatched: 'Quiz content watched',
      questionTimeGraphCorrect: 'Correct',

      percentGraphCorrect: "{0} correct",
      percentGraphIncorrect: "{0} incorrect",
      percentGraphSeriesName: "Percent Correct",

      downloadDetailsReport: "Detailed CSV",
      downloadSummaryReport: "Summary CSV",

      averageScore: "Average Score",
      averageResultsQuestion: "Average Results by Question",
      numberQuizzesTaken: "Quizzes Taken",
      averageContentViewed: "Average Content Viewed",
      averageCorrect: "Average Correct",
      topScore: "Top Score",
      lowestScore: "Lowest Score",

      responseQuestionType: "Question Type",
      responseQuestion: "Question",
      responseViewerAnswer: "Viewer Answered",
      responseCorrectAnswer: "CorrectAnswer",
      responsePossibleAnswers: "Possible Answers",
      responseAnswerCorrect: "Answer Was Correct",

      setName: "Question Set Name",
      setPercentViewed: "Percentage of segment viewed",
      setTimeToComplete: "Time to Complete",
      setFinishTime: "Finish Time",
      setQuestions: "Questions",

      viewerDisplayNameHeader: "Display Name",
      viewerLastNameHeader: "Last Name",
      viewerFirstNameHeader: "First Name",
      viewerNameHeader: "Name",
      viewerHeader: "Viewer",
      viewerEmailHeader: "Email",
      viewerCorrectHeader: "Number Correct (%)",
      viewerStartTimeHeader: "Start Time",
      viewerPercentileHeader: "Percentile",
      viewerContentWatchedHeader: "Content Watched",

      viewerNumberCorrect: "Number Correct",
      viewerUnscoredQuestions: "{0} unscored questions",
      viewerStartTime: "Start Time",
      viewerTimeToComplete: "Time to Complete",
      viewerVideoDuration: "Video Duration",
      viewerPercentWatched: "Percentage of Quizzed Content Watched",
      viewerQuestionSets: "Question Sets",
      
      viewerReturnButton: "Return",
      viewerScoreHeader: "Score",
      viewerIncorrectResponses: "Incorrect Responses ({0})",
      viewerContentWatched: "Section Content Watched",
      viewerAnswerGiven: "Answer given:",
      viewerCorrectAnswer: "Correct answer:",
      viewerAllResponses: "All Responses",
      
      noAnswerGiven: "<No Response>",
      
      questionsViewers: "Viewers",
      questionsAnswers: "Answers",
      questionResponseTotal: "{0} total",

      errorFailedToLoadReport: "Failed to load report data",

      amazingQuizText1: "You've received so many responses that we can't show them all here. Download the ",
      amazingQuizText2: " to view everything.",
      amazingQuizDownloadDetailsCsv: "detailed CSV"
   };
}(window));