(function (window) {
   window.techsmith = window.techsmith || {};
   window.techsmith.strings = window.techsmith.strings || {};
   window.techsmith.strings.dashboard = {
      unnamedQuiz: "(Unnamed Quiz)",
      
      tableHeaderActions: "Actions",
      tableHeaderTitle: "Title",
      tableHeaderDateCreated: "Date Created",
      
      tableItemViewReport: "View Report",
      tableItemShareFathom: "Share Fathom",
      tableItemPreviewFathom: "Preview Fathom",
      tableItemRemoveFathom: "Remove Fathom",
      
      tableItemOnDeleteLinkedAssessment: "Removing the quiz will unlink the quiz from your account.",
      tableItemOnDeleteEditableAssessmentForImage: "Deleting this quiz will erase existing results and analytics for this image.",
      tableItemOnDeleteEditableAssessmentForVideo: "Deleting this quiz will erase existing results and analytics for this video.",

      dashboardEmpty: "Your dashboard is empty.",
      dashboardEmptyMessage: "You can create quizzes with:",
      camtasia: "Camtasia",
      relay: "TechSmith Relay",
      listHeader: "Here's how:",
      camtasiaList1: "Open your video in Camtasia and add quizzes to your video. ",
      camtasiaList2: "Produce and share your video.",
      camtasiaList3: "Your first nightly report will be emailed to you. Follow the instructions in that email to link your quiz to TechSmith Results.",
      relayList1: "From TechSmith Relay, under your Media tab, select the video or image you want to add a quiz to.",
      relayList2: "Click the Quizzing tab and then click Add Questions.",
      relayList3: "Add your quiz questions and click Save Quiz. Your quiz will be saved and linked to TechSmith Results.",

   };
}(window));
